import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatalexClient, IHourRegistrationMaingroupBE, UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';
import { IgxButtonModule, IgxColumnComponent, IgxGridComponent, IgxInputGroupModule, IRowSelectionEventArgs } from '@infragistics/igniteui-angular';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { ButtonRowButtonComponent } from "../../../button-row-button/button-row-button.component";
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { GridFilterInputComponent } from "../../../grid-filter-input/grid-filter-input.component";

@Component({
  selector: 'dlx-hour-registration-main-group-editor',
  standalone: true,
  imports: [IgxGridComponent, IgxColumnComponent, IgxInputGroupModule, IgxButtonModule, FormsModule, CommonModule, ButtonRowButtonComponent, GridFilterInputComponent],
  templateUrl: './hour-registration-main-group-editor.component.html',
  styleUrl: './hour-registration-main-group-editor.component.scss'
})
export class HourRegistrationMainGroupEditorComponent implements OnInit {

  dlxClient = inject(DatalexClient);
  sys = inject(SystemCacheService);
  userRights = inject(UserRightsProviderService);
  maingroups: IHourRegistrationMaingroupBE[] = [];
  canEdit: boolean = false;
  showEdit: boolean = false;
  isNew: boolean = false;

  code: string = '';
  name: string = '';
  selectedMainGroup: IHourRegistrationMaingroupBE | null = null;

  ngOnInit(): void {
    this.canEdit = this.userRights.checkAccess(UserAreaEnum.REGISTERS).accessId.toUpperCase() === UserRightTypeEnum.FULL;
    this.dlxClient.GetHourRegistrationMaingroups().subscribe({
      next: (result) => {
        this.maingroups = result;
        console.log(this.maingroups);
      }
    })
  }

  onMainGroupSelection(evt: IRowSelectionEventArgs) {
    if(this.canEdit) {
      this.showEdit = true;
      this.isNew = false;
    }
    this.selectedMainGroup = evt.newSelection[0] as IHourRegistrationMaingroupBE;
    this.code = this.selectedMainGroup.Code;
    this.name = this.selectedMainGroup.Name;
    console.log(this.selectedMainGroup);
  }

  onCancle(){
    this.code = '';
    this.name = '';
    this.selectedMainGroup = null;
    this.isNew = false;
  }

  onSave() {
    if (this.selectedMainGroup !== null && this.selectedMainGroup.DeleteAllowed === false) {
      console.warn("Cannot edit this main group");
      return;
    } else {
      if(this.selectedMainGroup !== null && this.isNew === false){
        this.selectedMainGroup.Code = this.code;
        this.selectedMainGroup.Name = this.name;
        this.dlxClient.SaveHourRegistrationMaingroup(this.selectedMainGroup).subscribe({
          next: (mainGroup) => {
            this.code = "";
            this.name = "";
            this.maingroups.push(mainGroup);
          }
        })
      } else {
        this.dlxClient.NewHourRegistrationMaingroup().subscribe({
          next: (result) => {
            result.Active = true;
            result.DeleteAllowed = true;
            result.Code = this.code;
            result.Name = this.name;
    
            this.dlxClient.SaveHourRegistrationMaingroup(result).subscribe({
              next: (mainGroup) => {
                this.code = "";
                this.name = "";
                this.maingroups.push(mainGroup);
              }
            })
          }
        })
      }
    }
    
  }

  toggleActiveStatus(){
    
    if(this.selectedMainGroup) {
      this.selectedMainGroup.Active = !this.selectedMainGroup.Active;
      this.dlxClient.SaveHourRegistrationMaingroup(this.selectedMainGroup).subscribe({
        next: (mainGroup) => {
          this.dlxClient.GetHourRegistrationMaingroups().subscribe({
            next: (result) => {
              this.maingroups = result;
              this.selectedMainGroup = mainGroup;
            }
          })
        }
      })
    }

  }


  disableSaveButton(){
    if (this.selectedMainGroup !== null && this.selectedMainGroup.DeleteAllowed === false) {
      // console.log("Cannot edit this main group");
      
      return true;
    }
    if (this.code === '' || this.name === '') {
      // console.log("Nothing to save");
      return true;
    }

    // console.log('can save')
    return false;
  }

}
