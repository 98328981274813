<div class="button-row">
    <div class="button-cluster">
        <app-button-row-button [enabled]="canEdit" label="Ny" icon="add"
            (click)="onCancle(); showEdit = true; isNew = true" />
        <app-button-row-button [enabled]="showEdit" label="Avbryt" icon="cancel"
            (click)="showEdit = false; onCancle()" />
        <app-button-row-button [enabled]="selectedMainGroup !== null"
            [label]="selectedMainGroup !== null ? selectedMainGroup.Active ? 'Deaktiver' : 'Aktiver' : 'Deaktiver'"
            [icon]="selectedMainGroup !== null ? selectedMainGroup.Active ? 'do_not_disturb_on' : 'task_alt' : 'do_not_disturb_on'"
            (click)="toggleActiveStatus()" />
    </div>
</div>
@if (canEdit && showEdit) {
<div class="edit-panel">
    <h2>Legg til ny:</h2>
    <div class="editor-inputs">
        <igx-input-group class="code-input" displayDensity="compact" type="border">
            <label igxLabel>Kortform</label>
            <input igxInput type="text" [(ngModel)]="code" maxlength="3" required>
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border">
            <label igxLabel>Navn</label>
            <input igxInput type="text" [(ngModel)]="name" required>
        </igx-input-group>
        <button igxButton="raised" igxButtonColor="white" igxRipple="white" (click)="onSave()"
            [disabled]="disableSaveButton()">Lagre</button>
    </div>
</div>
}

<igx-grid #grid [data]="maingroups" [rowSelection]="'single'" displayDensity="compact"
    (rowSelectionChanging)="onMainGroupSelection($event)" hideRowSelectors="true" [allowFiltering]="true">
    <igx-column width="140px" field="Code" header="Kortform" [sortable]="true" dataType="string"
        [filterCellTemplate]="defaultFilterTemplate"/>
    <igx-column field="Name" header="Navn" [sortable]="true" dataType="string"
        [filterCellTemplate]="defaultFilterTemplate"/>
    <igx-column width="80px" field="Active" header="Aktiv" [sortable]="true" dataType="boolean"
        [filterCellTemplate]="defaultFilterTemplate"/>
</igx-grid>


<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>